.home-clue-card-box {
  position: fixed;
  bottom: 30vh;
  left: 36px;
  display: flex;
  flex-direction: column;
}
.home-clue-card {
  transition: transform 750ms;
  will-change: transform;

  width: 400px;
  height: auto;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.home-clue-card:hover,
.home-clue-card:focus {
  /* transform: translateY(100px); */
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.visible2 {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
  transition-delay: 1s;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
