@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*
{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

html,
body
{
    /* overflow: hidden; */
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.nav-container
{
    width: 100%;
    /* background-color: blue; */
    display: flex;
    position: absolute;
}

.navbar
{
    width: 100%;
    height: 56px;
    color: white;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    margin: 24px;
    align-items: center;
}

.navbar .logo 
{
    letter-spacing: -1px;
    font-size: 1.5rem;
    font-weight: 500;
}

.navbar .create
{
    font-weight: 500;
    background-color: #001F54;
    text-transform: uppercase;
    justify-self: end;
    color: white;
    height: 48px;
    padding: 0px 32px;
    border-radius: 64px;
    border: 0px;
    cursor:pointer;
}


.landing-page
{
    position: absolute;
    margin-top: 96px;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
}

.landing-page .hero-text
{
    width: 600px;
    margin-left: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

h1
{
    font-weight: 600;
    color: white;
    font-size: 3.5rem;
    line-height: 3.5rem;
}

h6
{
    margin-top: 24px;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: white;
}

.get-started 
{
    margin-top: 48px;
    font-weight: 500;
    background-color: #001F54;
    text-transform: uppercase;
    justify-self: end;
    color: white;
    height: 48px;
    padding: 0px 32px;
    border-radius: 64px;
    border: 0px;
    cursor:pointer;
}

.see-how-it-works  {
  display: none;
}
@media only screen and (min-width: 1000px) {
  .see-how-it-works 
  {
    display: block;
    margin-top: 48px;
    font-weight: 500;
    background-color: #04A130;
    text-transform: uppercase;
    justify-self: end;
    color: white;
    height: 48px;
    padding: 0px 32px;
    border-radius: 64px;
    border: 0px;
    cursor:pointer;
    margin-left: 20px;
  }
}


.first-clue
{
    position: absolute;
    display: flex;
    margin-top:95vh;
    width: 100vw;
    justify-content: end;
    text-align: center;
}

.first-clue .clue-contents
{
    margin-right: 16px
}

.fadeOut
{
    animation: fadeOut 1s forwards;
}

@keyframes fadeOut
{
    0% {opacity: 1}
    100% {opacity: 0}
}

