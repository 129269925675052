.create-cluetrail-page {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

#btn-randomize-all {
    margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  #btn-randomize-all {
    margin-left: 0;
    margin-top: 15px;
    width: 310px;
  }
}

#card-box {
  margin-left: 30px;
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  width: 95vw;
}

.card-box-outside {
  margin-right: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  #card-box {
    width: 100vw;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-box-outside {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
