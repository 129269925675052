.clue-card {
  width: 400px;
  height: 425px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .clue-card {
    width: 350px;
    height: 400px;
    position: relative;
  }
}

.clue-card_title-box {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.clue-card_title {
  margin: 0px;
  font-size: 24px;
  padding-top: 1px;
  font-family: Lato;
}

.clue-card_answer {
  font-family: Bebas Neue !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-top: 15px !important;
  margin-bottom: -2px !important; 
  color: #001F54 !important;
}

.clue-card_answer-text {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
